/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState } from "react";
import "../common/ui/css/RankLiveStanding.css";
import socket from "../socket";
import { useNavigate } from "react-router-dom";
import TableHeader from "../common/components/TableHeader";
import MainHeading from "../common/components/MainHeading";
import {
  ExtractFirstLetter,
  GetOrdinalSuffix,
} from "../common/utils/functions";
import DataLoadingSkeleton from "../common/components/loader";

const LiveStandingRank = () => {
  const tableHeaderData = [
    { name: "Rank", width: "10%", class: "" },
    { name: "Player", width: "27%", class: "" },
    { name: "team", width: "27%", class: "" },
    { name: "laps", width: "10%", class: "text-center" },
    { name: "best lap", width: "16%", class: "text-center" },
    { name: "Score", width: "10%", class: "text-center" },
  ];

  const navigate = useNavigate();

  const [newPlayer, setNewPlayer] = useState([]);

  const updateScoreByCarID = (car_id, scorePoints, car_ranks) => {
    setNewPlayer((prevNewPlayer) => {
      // Update scores
      const updatedPlayers = prevNewPlayer.map((player) => {
        if (player.car_id == car_id) {
          const newScore = player.score + scorePoints;
          return { ...player, score: newScore };
        }
        return player;
      });

      // Create a map from car_id to position from car_ranks
      const carPositionMap =
        car_ranks.car_status &&
        car_ranks.car_status.reduce((acc, car) => {
          acc[car.carId] = car.position;
          return acc;
        }, {});

      // Sort the players based on car position
      const sortedPlayers = updatedPlayers.sort((a, b) => {
        const positionA = carPositionMap[a.car_id];
        const positionB = carPositionMap[b.car_id];
        return positionA - positionB; // Adjust sorting based on position
      });

      return sortedPlayers;
    });
  };

  const updateCarHitScore = (car_id, scorePoints) => {
    setNewPlayer((prevNewPlayer) => {
      return prevNewPlayer.map((player) => {
        if (player.car_id === car_id) {
          return { ...player, score: player.score + scorePoints };
        }
        return player;
      });
    });
  };

  function convertToMinutes(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  }

  const updateScore = (driver_guid, scorePoints) => {
    setNewPlayer((prevNewPlayer) => {
      return (
        prevNewPlayer &&
        prevNewPlayer.map((player) => {
          if (player.driver_guid == driver_guid) {
            return { ...player, score: player.score + scorePoints };
          }

          return player;
        })
      );
    });
  };

  const updateLaps = (driver_guid, value) => {
    setNewPlayer((prevNewPlayer) => {
      return (
        prevNewPlayer &&
        prevNewPlayer.map((player) => {
          if (player.driver_guid === driver_guid) {
            return { ...player, lapCount: (player.lapCount || 0) + value };
          }
          return player;
        })
      );
    });
  };

  const updateBestLaps = (driver_guid, bestLapTime) => {
    setNewPlayer((prevNewPlayer) => {
      const minTime = convertToMinutes(bestLapTime);

      return prevNewPlayer.map((player) => {
        if (player.driver_guid === driver_guid) {
          return { ...player, bestLap: minTime };
        }

        return player;
      });
    });
  };

  // update the team name of a player
  const addTeamName = (car_id, team_name) => {
    setNewPlayer((prevNewPlayer) => {
      return prevNewPlayer.map((player) => {
        if (player.car_id == car_id) {
          return { ...player, team_name: team_name };
        }
        return player;
      });
    });
  };

  // Team name event - recieve team name from BE
  useEffect(() => {
    const handleEvent = (data) => {
      if (data.team_id) {
        addTeamName(data.car_id, data.team_id.team_name);
      }
    };

    socket.on("team_name_updated", handleEvent);

    return () => {
      socket.off("team_name_updated", handleEvent);
    };
  }, []);

  // overtake & position event
  useEffect(() => {
    const handleOverTake = (data) => {
      const { car_id, scorePoints, car_ranks } = data;
      updateScoreByCarID(car_id, scorePoints, car_ranks);
    };

    socket.on("car_overtake", handleOverTake);

    return () => {
      socket.off("car_overtake", handleOverTake);
    };
  }, [newPlayer]);

  // client_new_player_data
  useEffect(() => {
    // Set up the interval to emit the event every 5 seconds
    const interval = setInterval(() => {
      socket.emit("client_new_player_data", {
        message: "Give me the details of the new driver",
      });
    }, 5000);

    // Listen for the data emitted from the backend
    socket.on("new_player_data", function (data) {
      // console.log("new_player_data...........", data);
      if (data && Object.keys(data).length > 0) {
        const { car_id, driver_guid, driver_name } = data;
        // Check if the player already exists in the state based on car_id, driver_guid, and driver_name
        const playerExists = newPlayer.some(
            (player) =>
                player.car_id === car_id &&
                player.driver_guid === driver_guid &&
                player.driver_name === driver_name
        );

        if (!playerExists) {
          // If the player does not exist, add them to the state
          setNewPlayer((prevNewPlayer) => [...prevNewPlayer, data]);
        }
      }
    });

    // Clean up function to clear interval and socket listener on component unmount
    return () => {
      clearInterval(interval); // Clear the interval
      socket.off("new_player_data"); // Clean up the event listener
    };
  }, []);
  // collision_with_env
  useEffect(() => {
    const handleEnvCollision = (data) => {
      const { driver_guid, scorePoints } = data;
      updateScore(driver_guid, scorePoints);
      if (data.team_id) {
        addTeamName(data.car_id, data.team_id.team_name);
      }
    };

    socket.on("collision_with_environment", handleEnvCollision);

    return () => {
      socket.off("collision_with_environment", handleEnvCollision);
    };
  }, []);

  // lap_completed
  useEffect(() => {
    const handleLapCompleted = (data) => {
      const { driver_guid, isImprove, scorePoints, laptime } = data;
      updateScore(driver_guid, scorePoints);
      updateLaps(driver_guid, 1);

      if (isImprove) {
        updateBestLaps(driver_guid, laptime);
      }

      if (data.team_id) {
        addTeamName(data.car_id, data.team_id.team_name);
      }
    };

    socket.on("lap_completed", handleLapCompleted);

    return () => {
      socket.off("lap_completed", handleLapCompleted);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // collision_with_car
  useEffect(() => {
    const handleCarCollision = (data) => {
      const { car_id, scorePoints } = data;
      updateCarHitScore(car_id, scorePoints);

      if (data.team_id) {
        addTeamName(data.car_id, data.team_id.team_name);
      }
    };

    socket.on("collision_with_car", handleCarCollision);

    return () => {
      socket.off("collision_with_car", handleCarCollision);
    };
  }, []);

  // move_to_session_stats_screen
  useEffect(() => {
    const handleSessionStat = (data) => {
      navigate("/sessionstats");
    };

    socket.on("final_screen", handleSessionStat);
    return () => {
      socket.off("final_screen", handleSessionStat);
    };
  }, [navigate]);

  return (
    <div
      id="live-standing-rank"
      className="main-container  top-container bg-ranklivestanding-bgimage "
    >
      <div className="w-full flex flex-col gap-4 items-center">
        <MainHeading title="Live standing!" />

        <div className="w-full flex justify-center">
          <div className="flex flex-col   min-w-[90%]">
            <TableHeader elements={tableHeaderData} />

            {!newPlayer || newPlayer.length < 1 ? (
              <DataLoadingSkeleton />
            ) : (
              newPlayer
                // ?.sort((a, b) => b.score - a.score)
                ?.map((val, ind) => {
                  return (
                    <div
                      className="w-full  mt-4 shadow-custom-inset border-[0.125rem] rounded-[0.75rem]  border-solid border-[#ffffff] livvic flex items-center justify-between pt-[10px] pr-[20px] pb-[10px] pl-[20px] md:pt-[10px] md:pr-[20px] md:pb-[10px] md:pl-[20px]"
                      key={ind}
                    >
                      <div className="  w-[10%]  ">
                        <h1 className="result-data-text">
                          {GetOrdinalSuffix(ind + 1)}
                        </h1>
                      </div>

                      <div className=" players-sec  w-[27%] ">
                        <div className="w-[4rem] h-[4rem] flex items-center justify-center bg-white rounded-full ">
                          <span className="font-[900] text-[#c42121] livvic uppercase text-[2.3rem] ">
                            {ExtractFirstLetter(
                              val.driver_name ? val.driver_name : "-"
                            )}
                          </span>
                        </div>
                        <h1 className="result-data-text capitalize">
                          {val.driver_name ? val.driver_name : "-"}
                        </h1>
                      </div>

                      <div className=" w-[27%]  ">
                        <h1 className="result-data-text ">
                          {val.team_name ? val.team_name : "-"}
                        </h1>
                      </div>

                      <div className=" w-[10%]  text-center">
                        <h1 className="result-data-text">{val.lapCount}</h1>
                      </div>

                      <div className=" w-[16%]   text-center">
                        <h1 className="result-data-text">{val.bestLap}</h1>
                      </div>

                      <div className=" w-[10%]   text-center">
                        <h1 className="result-data-text">{val.score}</h1>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveStandingRank;
